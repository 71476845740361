import type { LinksFunction, MetaFunction } from "@remix-run/cloudflare";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import "./styles/tailwind.css";

export const meta: MetaFunction = () => {
	return [{ title: "Daniel Levett" }];
};

export const links: LinksFunction = () => {
	return [
		{ rel: "preconnect", href: "https://fonts.googleapis.com" },
		{
			rel: "preconnect",
			href: "https://fonts.gstatic.com",
			crossOrigin: "anonymous",
		},
		/* TODO: Reduce number of font weights used. Currently specifying them all for dev purposes. */
		{
			rel: "stylesheet",
			href: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap.js",
		},
		{
			rel: "stylesheet",
			href: "https://fonts.googleapis.com/css2?family=Caveat:wght@400&display=swap.js",
		},

		// TODO: Favicons
	];
};

// TODO: Better styles 500 page, and 404 page
export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return <div>Something went wrong</div>;
};

export default function App() {
	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				<Outlet />
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}
